/* /layouts/PortalPatientLayout.module.css */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #6AD2E2;
  color: white;
  padding: 10px;
  text-align: center;
}

.nav {
  background-color: #f8f9fa;
  padding: 10px;
}

.nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.nav li {
  margin: 0 10px;
}

.nav a {
  text-decoration: none;
  color: #6AD2E2;
}

.main {
  flex: 1;
  padding: 20px;
}

.footer {
  background-color: #f8f9fa;
  color: #6c757d;
  text-align: center;
  padding: 10px;
}

.LoginBg {
  background-size: cover;
  background-position: center center;
}

.BgTop {
  border-top: 8px solid #08312A;
}

/* .GridPortalPatientLayout {
  min-height: 768px;
} */
