.title {
    color: rgb(59, 59, 59);
    font-size: 2rem;
}

.information {
    color: black;
}

.selectContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
  
.banner {
    background-color: #fff3cd; 
    color: #854204;
    padding: 16px;
    border-radius: 15px;
    margin-bottom: 40px;
    margin-top: 30px;
    font-size: 1rem;
    font-weight: normal;
}
  
.banner strong {
    font-weight: bold;
}

.errorText {
    color: red;
}
