/* /components/PortalPatient/Footer.module.css */

.Container {
    padding: 40px 0;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px 0px #00000014 inset;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
    flex-wrap: wrap;
    color: #08312A;
  }
  
  .Contact{
    margin-top: 18px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .Heading {
    max-width: 171px;
    flex-direction: column;
  }
  
  .Heading div{
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 6px;
  }
  
  .Links {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .Links a {
    color: #08312A;
    text-decoration: none;
  }
  
  .Links a:hover {
    text-decoration: underline;
  }
  
  .Branding{
    display: flex;
    align-self: center;
  }
  
  .Branding img {
    height: 60px; /* ajuste conforme necessário */
  }
  
  .Disclaimer {
    text-align: center;
    font-size: 14px;
    background-color: #08312A;
    padding: 1%;
    color: white;
  }
  
  .Disclaimer p{
    margin:1% 25%;
  }
  
  .Footer {
    font-size: 14px;
  }
  
  .Email{
    flex-direction: column;
  }
  
  .Email div{
    height: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .Small{
    margin: 0px;
    font-size: 12px;
  }
  
  @media screen and (max-width: 567px) {
  
    .Container{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }