.Alert{
  max-width: 479px;
  width: 100%;
  position: fixed;
  right: 80px;
  top: 40px;
  z-index: 9999;
}

.AlertDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 430px;
}
.AlertButton {
  position: relative !important;
  color: #fff !important;
}
@media (max-width: 680px) {
  .Alert{
    max-width: 479px;
    width: auto;
    position: fixed;
    right: 10px;
    top: 10px;
    padding-right: 45px !important;
  }
  .AlertButton {
    right: 10px;
    top: 10px;
  } 
}

@media(max-width: 600px) {
  .Alert {
    width: 300px;
  }

  .AlertDiv {
    width: 220px;
  }
}