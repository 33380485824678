.PageTitle {
    font-weight: bold !important;
    color: black !important;
    font-size: 2rem !important;
    text-align: left !important;
    width: 100% !important;
    margin-bottom: 0.5rem !important;
  }

.ParagraphTitle {
    font-weight: bold !important;
    color: black !important;
    font-size: 1rem !important;
    text-align: left !important;
    width: 100% !important;
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
  }