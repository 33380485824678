/* LoginPage.module.css */

.LoginBg {
  background-size: cover;
  background-position: center center;
}

.CaptchaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReloadIcon {
  cursor: pointer;
  background-color: var(--Support-Info-Info20, #6AD2E2);
  color: #fff;
  margin: 10px;
  max-width: 30px;
  border-radius: 10px;
  max-height: 30px;
}

.ButtonLogin {
  background: var(--Support-Info-Info40, rgba(0, 228, 124, 1)) !important;
  color: #08312A !important;
  font-weight: bold !important;
  border-radius: 999px !important;
  -webkit-border-radius: 999px !important;
  -moz-border-radius: 999px !important;
  -ms-border-radius: 999px !important;
  -o-border-radius: 999px !important;
}

.ForgotPassword {
  color: #00E47C !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: center;
}

@media (max-width: 1280px) {
  .LoginBg {
    display: none;
  }
}

@media (max-width: 600px) {
  .textFieldMargin {
    margin-top: 16px;
    margin-bottom: 30px;
  }
}

.CaptchaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}