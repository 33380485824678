@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 24vh;
  max-width: 48vh;
  width: 100%;
  overflow: hidden;
}

.LoaderText {
  margin: 16px 0 0 0;
  font-size: 16px;
}

.dot {
  animation: blink 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

.DivCircle {
  width: 60px;
  height: 60px;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(28, 59, 169, 0) 0deg,
    #1C3AA9 359.64deg,
    rgba(28, 59, 169, 0) 0deg
    );
  border-radius: 50%;
  animation: rotate 2s linear infinite;
}

.DivCircle::after {
  content: "";
  display: inline-block;
  position: relative;
  top: 12.5px;
  left: 12.5px;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
}

@media (max-width: 1500px) {
  .LoaderContainer{
    height: 34vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
  }
}