.PopupContainer {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.QuestionText {
  font-size: 18px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .QuestionText {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: nowrap;
}

.ButtonContainer button {
  flex: none;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 12px;
  font-size: 14px;
}

@media (max-width: 600px) {
  .PopupContainer {
    padding: 15px;
  }

  .ButtonContainer {
    flex-direction: column;
    gap: 8px;
  }

  .ButtonContainer button {
    width: 100%;
    max-width: none;
    text-align: center;
  }
}
